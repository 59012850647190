.site-logo {
    height: 100px;
    width: 100px;
}

.top-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
}

.add-new-class-btn{
    padding-top: 15px;
    padding-right: 15px;
    float: right !important;
}

.default-btn{
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
  color: #000;
}

.page-content {
    padding-top: 70px;
}

.page-title {
    padding-top: 10px;
    text-align: center;
}

.home-page-p{
    width: 80%;
    text-align: center !important;
    padding-top: 20px;
}

.home-page-p-container{
    justify-content: center !important;
    display: flex;
}

.home-page-img{
  height: 400px;
}

.loading-container{
    justify-content: center !important;
    display: flex;
    padding-top: 200px;
}

.select-year-btn{
    padding-left: 15px;
}

.centered{
    text-align: center;
}

.no-class-row{
    text-align: center;
}

.float-right{
    float: right !important;
}

.no-list-style{
    list-style-type: none;
}

.warning {
    background-color: #db535f;
}

.success {
    background-color: #249725;
}


.loader {
    width: 15px;
    aspect-ratio: 1;
    position: relative;
  }
  .loader::before,
  .loader::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: #000;
  }
  .loader::before {
    box-shadow: -25px 0;
    animation: l8-1 1s infinite linear;
  }
  .loader::after {
    transform: rotate(0deg) translateX(25px);
    animation: l8-2 1s infinite linear;
  }
  
  @keyframes l8-1 {
      100%{transform: translateX(25px)}
  }
  @keyframes l8-2 {
      100%{transform: rotate(-180deg) translateX(25px)}
  }


  .accordion-button:not(.collapsed){
    color: #000;
    background-color: #c8efd4;
  }

  .accordion-button::after{
    color: #000;
  }

  .accordion{
    --bs-accordion-btn-focus-box-shadow: rgb(82 87 95 / 25%);
  }

  /* .nav-link button{
    color: #fff !important;
  }

  .nav-link{
    color: #7c78788c;
    
  } */

  .nav-link.hover{
    color: #7c78788c;
    background-color: #7c78788c;
  }

.nav{
  --bs-nav-link-hover-color: #116c2c;
}


.receipt-container {
  padding-top: 50px;

  justify-content: center !important;
  display: flex;
}

.receipt-img{
  width: 300px;
  border: solid #000 5px;
}

.button-container > button {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid rgb(231, 50, 50);
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 10px #333;
  overflow: hidden;
  transition: .3s;
  opacity: 50%;
}

.button-container > button:hover {
  background-color: rgb(245, 207, 207);
  transform: scale(1.2);
  box-shadow: 0 0 4px #111;
  transition: .3s;
  opacity: 100%;
}


.button-container{
   display: block;
   float: right;  
   z-index: 3;
   position: absolute; 
   margin-top: 10px;
   margin-left: 240px
}